import React from "react"

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import "aos/dist/aos.css";
import get from 'lodash/get'

import ContactQuery from "@components/contact-query/contact-query";
import Valuation from "@components/valuation/valuation";
import { graphql } from 'gatsby'

function getParameterByName(name) {
	if (typeof window !== "undefined") {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
}

class Contact extends React.Component {


	render() {
		var officecontact = getParameterByName('contact');
		const Page = get(this, 'props.data.strapiArticles')
		const Modules = get(this, 'props.data.strapiArticles.Modules')
		const TeamMember = get(this, 'props.data.strapiArticles.Team_Members')

		let office_details = typeof window !== "undefined" && JSON.parse(window.localStorage.getItem('office_details'))
		return (
			<>
			<div class="Contact_landing_page">
				<Layout popularSearch={Page.Select_Popular_Search}>
				<SEO title={Page.Meta_Title} description={Page.Meta_Description} />

				{Modules.map((Modules, i) => {
					return (
						<>
						<ContactQuery officecontact={officecontact}	 officeinfo = {office_details} data={Modules} team={TeamMember} />
						</>
						)
				})}
				<Valuation />
				</Layout>
			</div>
			</>
			)
	}
}
export default Contact

export const pageQuery = graphql`
query ContactQuery {

    strapiArticles(id: {eq: "Articles_5fc09d8229e1721c1dc7f0b8"}) {
		Alias
		id
		Meta_Description
    	Meta_Title
		Pagename
		Layout
		Select_Popular_Search
		Modules {
			Intro_Content
			Intro_Title
		}
		Team_Members {
          Designation
          Email
          Image {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
          }
          Name
          Phone
    	}
	}
}
`