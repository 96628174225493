import React from 'react';
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { Col, Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'

/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */
import ContactQueryForm from '@blocks/forms/contact-query';
import GeneralEnquiryForm from '@components/forms/general-enquiry';
import OfficeContactForm from '@components/forms/office-contact';
import TextColumns from '@components/text-columns/text-columns';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import ContactBox from '@components/contact-box/contact-box';
import OfficeBox from '@components/office-box/office-box';
import { useLocation } from "@reach/router"
import { parse } from "query-string"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import SEO from "@components/seo"



const ContactQuery = (props) => {
    const GET_OFFICE=  gql`
    query officedetails ($URL: String!){   
        offices (where:{URL: $URL}){
          Title
          Email
          URL
          Address
          Tile_Image {
            url
          }
          Team_Members {
            Name
            Email
            Phone
            Designation
            Image {
              url
            }
          }
        }
      }
    `;
    const location = useLocation();
    const searchParams = parse(location.search) // => {init: "true"}   
    var office = searchParams.office
    const { loading, error, data } = useQuery(GET_OFFICE, {variables: { URL: office}});
    var contactoffice=null;
    if(data !=undefined) {
        contactoffice = data && data.offices[0];
    }
    return (
        <div className="contact-query form-page">
            <Container>
                {contactoffice != null &&
                <>
                    <SEO title={`${contactoffice.Title} Estate Agents | General Enquiry`} description={`Contact ${contactoffice.Title} Estate Agents for any general property enquiry and we will be happy to help you.`} />
                    <Breadcrumbs alias='our-offices'/>
                </>
                }
                {contactoffice == null &&
                    <Breadcrumbs/>
                }
                {contactoffice == null &&    
                <h1>{props.data.Intro_Title}</h1> 
                }
                {contactoffice != null &&
                <h1>Contact {contactoffice.Title}</h1>
                }
                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.data.Intro_Content}/>
            </Container>
            <TextColumns>
                <Col md="12" lg={{
                    span: 7,
                }}>
                    <div className="content">
                    {contactoffice != null &&
                        <OfficeContactForm to_email_id={contactoffice.Email} />
                    }
                    {contactoffice == null &&
                        <GeneralEnquiryForm  />
                    }
                    </div>
                </Col>
                <Col md="12" lg={{
                    span: 4,
                    offset: 1
                }}>
                    
                {contactoffice != null &&
                    <>
                    <div className="office-box">
                        <div className="office-box__image">
                            <img src={contactoffice.Tile_Image && contactoffice.Tile_Image.url}/>
                        </div>
                        <div className="office-box__content">
                            <h4>{contactoffice.Title}</h4>
                            <p>{contactoffice.Address}</p>
                            {contactoffice.Team_Members && contactoffice.Team_Members.map((data, key) => {
                            return <>
                            <ContactBox name={data.Name} email={data.Email} phone={data.Phone} position={data.Designation} link={data.Calendly_Link} linkText="Video Call" teamimage={data.Image && data.Image.url}  />
                            </>
                            })}
                        </div>
                    </div>                   
                    </>
                }
                  
                </Col>
            </TextColumns>
        </div>
    )
}

export default ContactQuery
