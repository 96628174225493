import React, { useEffect } from 'react';
import { Row, Container } from 'react-bootstrap';

/**
 * Assets
 */

 import './styles/_index.scss';

const TextColumns = ({children}) => {
    let AOS;
    useEffect(() => {
      const AOS = require("aos");
      AOS.init({
        once: true,
        disable: 'mobile'
      });
    }, [])


    return (
        <div className="text-columns" data-aos="fade-up">
            <Container>
                <Row>
                    {children}
                </Row>
            </Container>
        </div>
    )
}

export default TextColumns;
